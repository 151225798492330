
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap');

.loader {
  display: none;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
span.anticon.anticon-exclamation-circle {
  display: none;
}
.controls.controls-wrap {
  flex-wrap: wrap;

}

canvas {
  position: absolute;
  z-index: 1 !important;
  width: 100%;
  height: 100%;
}
.controls {
  display: flex;
  justify-content: center;
  width: 100%;
}
.previous-history {
  color: #b8b8b7 !important;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #6f6f6f;
  padding: 0 10px;
  font-size: 12px;
  transition: all .3s;
}

.slidPro {
  height: 21px;
  background: #0093ff;
  border-radius: 13px 8px 8px 13px;
  position: absolute;
  top: 2px;
  min-width: 9%;
  margin-left: 3px;
}
.addextra{
  background-color: #cfe2ff75 !important;
}
input.ant-input.shadowmyadd {
  box-shadow: 0 0 6px 0px #4f92f7;
}
.bg-add{
  background-color: #d9d9d9 !important;
  pointer-events: none !important;
  }
  .bg-add02{
    pointer-events: none !important;
    }
.mycr {
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
  color: #000;
  text-decoration: underline;
}
.offcanvas-start {
  width: 300px !important;
 
}
bgbutton {
  background: #000;
}
button.rajababau {
  padding: 6px 7px;
  color: #ffffff;
  background: #0d9e0a;
  border-radius: 5px;
  cursor: none;
  border: 0px;
}
button.rajababau.raj78 {
  background: red;
}
h3.colorpoint {
  margin: 3px 0px 0px 4px;
  font-size: 15px;
  font-weight: 800;
  color: #f33d5a;
}
img.wifiji {
  width: 16px;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 6000 !important;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6000 !important;
  height: 100%;
  background-color: rgba(0,0,0,.45);
}

button.ant-btn.ant-btn-default.raj033333.btn.btn-primary {
  color: white;
  background: #0d6efd;
  margin-left: 14px;
}

.loading {
  border: 2px solid #ccc;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-top-color: #1ecd97;
  border-left-color:  #1ecd97;
  animation: spin 1s infinite ease-in;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}








* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
}
.ant-table-pagination-right {
  justify-content: center !important;
}


.onbuler {
  filter: blur(9px);
}

.fade.alert.alert-primary.show {
  font-size: 0.8rem;
}
.bg-light.text-dark.text-capitalize.card-header {
  text-align: center;

}
label.text-capitalize.text-start.w-100.form-label {
  font-size: 13px;
  margin-top: 5px;
}
.form-control {

  font-size: 14px !important;
}


.ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th {
  position: relative;
  padding: 12px !important;
  font-size: 11px !important;
  overflow-wrap: break-word;
}

body {
  background: #e1e1e1;
  font-family: 'Roboto', sans-serif !important;

}

h5.mr {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}
.m-0.w-100.countdoen p {
  padding: 0px;
  margin: 0px;
  text-align: center;
  font-size: 30px;
  font-family: poppins;
  font-weight: 700;
  color: #00c7ff;
}
.py-1.bg-white.border.px-2.text-dark.d-flex.align-items-center.rounded-2 {
  margin-right: 13px;
}
/* li.p-0.overflow-hidden.appear-from-left{
  -webkit-animation: leftToRight .7s linear;
    animation: leftToRight .7s linear;
} */
span.fw-semibold.text-truncate.text-success {
  width: 100px;
  font-weight: 700;
 
  font-size: 120%;
}
.d-flex.align-items-center.justify-content-between.card-body.myadd {
  padding: 11px 16px 0px 16px;
}
/* .d-flex.align-items-center.justify-content-center.card-header {
  box-shadow: -1px 2px 5px 0px #750ae15e;
} */

.d-flex.align-items-center.justify-content-center.card-header.view{
  box-shadow: 0px 2px 5px 0px #52cc75;
}
.card-footer.text-muted.myfooter.view{
  background: linear-gradient(90deg, rgb(251 153 27 / 0%) 0%, rgb(253 213 161 / 0%) 35%, rgb(12 184 61 / 73%) 96%);

}
.p-agggg {
  padding: 0px 7px 3px 7px;
  background: transparent !important;
}
.shadow02 {
  box-shadow: 0px 6px 9px #0000001f;
}


.d-flex.align-items-center.justify-content-center.card-header {
  box-shadow: 0px 2px 5px 0px #fcd4e1;
}
button.btn.btn-success.playChallange.btn-sm.mycoustm {
  padding: 4px 18px;
  background-color: hsl(136.9deg 81.61% 34.12%);
}
/* .card-footer.text-muted.myfooter {
  background: linear-gradient(90deg, rgb(255 0 0 / 9%) 0%, rgb(9 9 121 / 0%) 35%, rgb(255 41 176 / 10%) 75%);
} */


.card-footer.text-muted.myfooter {
  background: linear-gradient(90deg, rgb(251 153 27 / 0%) 0%, rgb(253 213 161 / 0%) 35%, rgb(241 39 104 / 20%) 96%);
}


.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
 border: none !important;
}
img.hello {
  width: 32px;
  margin-right: 10px;
}
.d-.flex.w-100.raj023 {
  display: flex;
  justify-content: space-between;
}
.kamal02{
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.header {
  background: #fff;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);  */
  padding: 15px 20px;
  width: 100%;
   /* position: fixed;  */
  top: 0;
  left: 0;
  z-index: 9;
}
.ram01 {
  padding: 10px;
}
img.w-10 {
  width: 10%;
}
button.btn.btn-lg.text-white.d-flex.align-items-center.justify-content-center.w-100.insta {
  background: linear-gradient(45deg, rgb(240, 148, 51) 0%, rgb(230, 104, 60) 25%, rgb(220, 39, 67) 50%, rgb(204, 35, 102) 75%, rgb(188, 24, 136) 100%);
}
button.toggle {
  background: transparent;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: 0;
}
.card-02 {
  padding: 20px 1rem 0px 1rem;
}
p.text-center.menn {
  font-size: 17px;
  margin: 0px 0px 0px 0px;
  font-weight: 600;
  font-family: poppins;
}








.wrapper {
  margin: auto;
  max-width: 640px;

  text-align: center;
}

.separator.mt-3.mb-3{
  display: flex;
    align-items: center;
    justify-content: space-evenly;
}
hr.line01 {
  height: 2px;
  color: #000;
  opacity: 100%;
  width: 21%;
}

.upload-container {
  background-color: rgb(239, 239, 239);
  border-radius: 6px;
  padding: 10px;
}

.border-container {
  border: 5px dashed rgba(198, 198, 198, 0.65);
/* /   border-radius: 4px; / */
  padding: 20px;
}

.border-container p {
  color: #130f40;
  font-weight: 600;
  font-size: 1.1em;
  letter-spacing: -1px;
  margin-top: 30px;
  margin-bottom: 0;
  opacity: 0.65;
}

#file-browser {
  text-decoration: none;
  color: rgb(22,42,255);
  border-bottom: 3px dotted rgba(22, 22, 255, 0.85);
}

#file-browser:hover {
  color: rgb(0, 0, 255);
  border-bottom: 3px dotted rgba(0, 0, 255, 0.85);
}

.icons {
  color: #95afc0;
  opacity: 0.55;
}







input.form-control.mye {
  border: none;
  text-align: center;
  font-size: 24px;
  color: red;
  font-family: poppins;
  font-weight: 700;
}
img.vs {
  width: 26px;
}
.hstack.gap-3 p {
  font-size: 20px;
  font-weight: 600;
}
.d-flex.rj232323.align-items-center.justify-content-center {
  width: 33.33%;
}
.toggle span {
  width: 100%;
  height: 2px;
  background: #555;
  display: block;
  position: relative;
  cursor: pointer;
}

.toggle span:before,
.toggle span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #555;
  transition: all 0.3s ease-out;
}

div#sidebar {
  z-index: 1001;
}


.toggle span:before {
  top: -8px;
}

.toggle span:after {
  top: 8px;
}

.toggle span.toggle {
  background: transparent;
}

.toggle span.toggle:before {
  top: 0;
  transform: rotate(-45deg);
  background: #4caf50;
}

.toggle span.toggle:after {
  top: 0;
  transform: rotate(45deg);
  background: #4caf50;
}



header.header {
  filter: drop-shadow(-4px -1px 4px #000);
  height: 60px;
}
.sidebar {
  background: #fff;
  width: 320px;
  position: fixed;
  top: 0px;
  left: -360px;
  height: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  /* / padding-top: 90px; / */
  transition: all 0.3s ease-out;
}
.d-flex.flex-column.align-items-stretch.justify-content-start.p-0.offcanvas-body {
  overflow-y: scroll;
  height: -webkit-fill-available;
}

.hstack.gap-3.rajuji {
  display: flex;
  align-items: center;
}

.sidebar ul {
  list-style: none;
}

.sidebar ul li {
  display: block;
}
.hstack.gap-1.rajuda{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sidebar ul li a {
  padding: 8px 15px;
  font-size: 24px;
  color: #222;
  font-family: arial;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
  font-weight: 500;
}

.sidebar ul li a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 1px;
  background: #4caf50;
  z-index: -1;
  transition: all 0.3s ease-out;
}

.sidebar ul li a:hover:before {
  width: 100%;
}

.sidebar ul li a:hover {
  color: #4caf50;
}

.sidebarshow {
  left: 0;
}



 



/*Ludo css */

/* .animated{
  width: 100px;
  height: 100px;
  background-color: red;
  position: relative;
  animation-name: example;
  animation-duration: 4s;
z-index: 999;
}
@keyframes example {
  0%   {background-color:red; left:-200px; top:0px;}
  25%  {background-color:yellow; left:0px; top:0px;}
 
} */

/* 
.p-3 {
  margin-left: 3%;
}
.homeScreenL {
  margin-top: 59px;
  margin-left: -25px;
}
button.btn.btn-danger.btn-sm.text-capitalize {
  width: auto;
  height: auto;
  font-size: 23px;
}
button.d-flex.align-items-center.btn.btn-outline-primary.btn-md {
  width: auto;
}
.fade.d-flex.align-items-center.justify-content-between.alert.alert-danger.show {
  position: static;
}
.mb-3.shadow.card {
  position: static;
}
.shadow.card {
  position: static;

}
button.btn.btn-primary.btn-lg.text-capitalize.mb-2 {
  width: auto;
height: auto;
}
.card-body {
  font-size: 30px;
}


span.text-capitalize.fw-bold {
  font-size: 1.8rem;
align-items: center;
justify-content: center;
display: flex;
}
span.fs-4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
b {
  font-size: 24px;
}
.bg-light.text-dark.text-capitalize.card-header {
  font-size: 24px;
  display: flex;
  justify-content: center;
}

.col-12.mx-auto.g-0.iframe-sec.p-3 {
  margin-top: 31px;
}
.container {
  margin-top: 31px;
}
button.text-capitalize.me-2.py-2.px-4.border.text-dark.badge.rounded-pill.text-white.bg-primary.btn.btn-primary {
  width: auto;
}
button.btn.btn-primary.text-uppercase {
  width: auto;
  height: auto;
} */

/* .fade.d-flex.align-items-center.justify-content-between.alert.alert-danger.show {
  margin-top: 59px;
} */

.realludokingsize{
  /* max-width: 480px; */
  max-width: 480px;

  /* / border-right: 10px solid rgb(224, 224, 224); / */
  
}
.rcBanner.flex-center{
   border-left: 10px solid rgb(224, 224, 224);
}

input#form1::placeholder {
    font-size: 14px;
}
input#form2::placeholder {
    font-size: 14px;
}
input#form4::placeholder {
    font-size: 14px;
}

.odbox {
  max-width: 480px;
  position: fixed;
  background: #fff;
  color: #666;
  font-family: sans-serif;
  border-radius: 5px;
  bottom: 0;
  left: 0;

  z-index: 1000;
}
img.menuimg01 {
  width: 30px;
}


.rightContainer {
  background-color: #fff;
  background-size: cover;
  overflow: hidden;
  right: 0;
  z-index: 4;

}
.rightContainer{
  left: 480px !important;
    z-index: 2000 !important;
}
.fixed {
  visibility: hidden;
}
img.wllet {
  width: 16px;
  margin-right: 10px;
}
.py-1.bg-white.border.px-2.text-dark.d-flex.align-items-center.rounded-2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
img.mx-auto.kmt {
  width: 54%;
  padding: 20px 0px 30px 0px;
}
.form-outline {
  width: 100%;
}
.alert02 {
  background: #000;
  padding: 6px;
  border-radius: 4px;
}
table {
  table-layout: fixed !important;
}
.master-snf-screen.container {
  border: 1px solid #000;
  padding: 0px !important;
}
.ant-table-thead>tr>th {
  position: relative;
  color: rgba(0,0,0,.85);
  font-size: 11px !important;
  font-weight: 800 !important;
  text-align: center !important;
  background: #00c7ff;
  border-bottom: 1px solid #f0f0f0;
  transition: background .3s ease;
}
.p-0.d-flex.raju15{
  width:32%;
  margin-right: 21px;
}
/* .py-1.bg-white.border.px-2.text-dark.d-flex.align-items-center.rounded-2 {
  width: 112px;
} */
.py-1.bg-white.border.px-2.text-dark.d-flex.align-items-center.rounded-2 {
  width: 90%;
}
.text-center.mainwelcome {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 800;
  background-image: linear-gradient(to right, #ffbf02, #ed0082);
  color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  font-family: poppins;
}
h1.text-capitalize.text-start.mainh1 {
  margin: 0px;
  font-size: 25px;
}
img.menuimg01 {
  width: 30px;
  align-items: center;
  justify-content: space-between;
}
a.bg-light.border.shadow.rounded-circle.d-flex.align-items-center.justify-content-center.position-fixed.text-dark.meniicon{
  height: 60px;
  width: 60px;
  bottom: 20px;
  z-index: 3000;
  right: 15px;
}
a.bg-light.border.shadow.rounded-circle.d-flex.align-items-center.justify-content-center.position-fixed.text-dark.\32 3 {
  height: 60px;
  width: 60px;
  bottom: 29px;
  z-index: 3000;
  right: 15px;
}
.fixed.\30 2 {
  position: fixed;
  visibility: unset;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 480px;
  /* / border-right: 10px solid rgb(224, 224, 224); / */
  z-index: 1000;
}

.rcBanner {
  height: 100%;

  left: 0;
  min-width: 320px;
  position: absolute;
  width: 100%
}

.flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center
}
a:active {
  color: #096dd9;
  color: #fff;
  border-bottom: 4px solid #fff;
}
.rcBanner-img-container {
  bottom: 45%;
  position: absolute;
  width: 291px
}
a.xtab.active {
  border-bottom: 4px solid #fff !important;
  font-size: 16px;
  color: #fff;
}

  
.offcanvas-bottom {
  right: 0;
  width: 480px ;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  transform: translateY(100%);
}
.rcBanner-img-container>img {
  height: auto;
  width: 250px
}

.rcBanner-logo-container {
  bottom: 35%;
  position: absolute
}

.rcBanner-logo-container>img {
  height: auto;
  position: relative;
  width: 330px
}

.rcBanner-text {
  bottom: 35%;
  font-family: Poppins;
  font-size: 2em;
  font-weight: 500;
  position: absolute;
  text-transform: uppercase
}

.rcBanner-text-bold {
  font-weight: 700;
    font-family: poppins;
    text-decoration: underline;
}
.bg-danger.py-2.text-white.w-100.text-center {
  font-weight: 600;
}

.rcBanner-footer a {
  font-weight: 700;
  text-decoration: underline;
}
p.ptaf {
  font-size: 15px;
  font-weight: 800;
  font-family: poppins;
  text-transform: uppercase;
  text-decoration: underline;
  color: #3F51B5;
}
h2.che {
  margin: 0px;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 18px;
  background-image: linear-gradient(to right, #ffbf02, #ed0082);
  color: rgba(0, 0, 0, 0);
    -webkit-background-clip: text;
    background-clip: text;
    position: relative;
    font-family: poppins;
}
span.fw-semibold.text-truncate {
  font-family: poppins;
  font-weight: 600;
  text-transform: capitalize;
}
/* span.text-capitalize {
  font-family: poppins;
  font-size: 16px;
  margin-left: 10px;
  font-weight: 500;
} */

span.text-capitalize.sup02 {
  font-size: 18px;
  font-weight: 600;
  margin-left: 8px;
}

.bg-light.text-dark.card-header {
  font-weight: 500;
  font-family: poppins;
}
span.text-success.fw-bold {
  font-family: poppins;
}
.rcBanner-footer {
  bottom: 40px;
  color: #2c2c2c;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  text-align: center
}

.rcBanner-footer>img {
  height: 25px;
  margin-top: 4px;
  width: 25px
}


.ant-table-pagination-right {
  text-align: center;
  justify-content: center;
}

input.form-control.p-2.referInput {
  z-index: -10000000;
  width: 1px !important;
}
input.form-control.p-2.WW56 {
  WIDTH: 65%;
}


marquee.card-body.myjikj {
  padding: 5px 0px 5px 0px;
  background: #000;
  border-radius: 3px;
}
h2.kl02.m-0.text-white {
  margin-right: 58px !important;
}





.rotate {

    transform:rotate(800deg);
    transition: 3s;
}




span.anticon.anticon-eye.showPassword.\30 2 {
  position: absolute;
  top: 159px;
  right: 36px;
}





.mclose1{
  display: none;
}



.Btnnewadd {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.164);
  cursor: pointer;
}

.textnewadd {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(2, 153, 153);
}

.svgIconnewadd {
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: teal;
}

.Btnnewadd:hover .textnewadd {
  background-color: rgb(0, 133, 133);
}

.Btnnewadd:hover .svgIconnewadd {
  background-color: rgb(0, 105, 105);
}